import {
    mapState
} from "vuex";
import {
    getChannelOrderList,
    cancleChannelOrder,
    exportOrderChannel,
    exportOrderChannelShen,
    changeTransFlag
} from "../api.js";
import { publicPath } from "@/common/constant.js";
import hImg2 from "@/components/global/hImg/hImg2";
import navUtil from "@/components/global/CustomizeNav/util";
import Util from "@/utils/utils";
import { findUserIdByShopAndPhone } from "@/views/order/details/api";
export default {
    name: 'channel-order',
    components: {
        hImg2
    },
    props: {
        account: {
            type: String
        }
    },
    data() {
        return {
            publicPath: publicPath,
            navList: [],
            pageLoadFlag: false, //load标志
            isChange: false, //海信 渠道 切换变为true
            searchChannelOrderNo: '', //搜索值
            //采购订单列表查询接口入参
            channelFilter: {
                terms: {
                    endDocumentDate: '', //结束时间
                    documentNum: '', //采购单单号
                    searchNum: '', //采购单单号、审核单号、产品型号
                    startDocumentDate: '', //开始时间
                    supplierCode: '', //供应商编码
                    status: '', //单据状态
                    materialGroupCode: '', //物料组编码
                    startSalesOrderDate: '', //审核单开始日期
                    endSalesOrderDate: '', //审核单结束日期
                    salesOrderStatus: '', //审核单状态
                },
                page: {
                    pageNo: 1, //第几页
                    pageSize: 20, //每页条数
                    sortName: 'createDate', //排序字段
                    sortOrder: 'desc', //排序，desc 或 asc
                }
            },
            channelOrderList: [],
            totalCount: '',
            isViewChannel: false,
            totalPagesChannel: '',
            nolistImgChannel: require('@/assets/order/noList.png'),
            showListChannel: false,

            spinOrderExport: false,
            spinShenExport: false,
            creatDateChannel: 1,
            isView: false,
            dateFormat: 'YYYY-MM-DD',
            orderList: [],
            breadcrumbData: [{
                path: "/index",
                name: "index",
                title: "首页",
            },
            {
                path: "/order/dms",
                name: "/order",
                title: "渠道订单明细",
            },
            ],
            currentPageChannel: '',
            searchOrderNo: '', //订单号
            ModalText: '确定取消订单？',
            visibleInfo:false, //是否显示取消订单弹框
            confirmLoading: false,
            cancleData: {
                searchStr: ''
            },
            nolistImg: require('@/assets/order/noList.png'),
            orderTypeId: '',
            ordertypeCancel: 1,
            shenLoading: false,
            orderLoading: false,
            isFenxiaoSign: '',
            isFenxiaoSignActive: '',
            cancelId: '', //要删除商品行id
            cancelItem: '', //要删除商品行明细
        }
    },
    computed: {
        ...mapState({
            //处理后的筛选数据list
            filList: (state) => state.filtrate.filList,
            isFenxiaoOrder: (state) => state.user.userInfo.account.marketModels,
            //筛选组件加载loadding
            filterLoadFlag: (state) => state.filtrate.isLoading,
            //用于查询列表的筛选配置字段
            filterConfigStr: (state) => state.filtrate.filterConfigStr,
        }),
    },
    mounted() {
        //导航数据
        this.navList = navUtil.orderList;


        if ((this.isFenxiaoOrder.indexOf('17452') !== -1 || this.isFenxiaoOrder.indexOf('17453') !== -1) && this.isFenxiaoOrder.indexOf('17451') == -1) {
            this.isFenxiaoSign = '2'
        } else if (this.isFenxiaoOrder.indexOf('17451') !== -1 && this.isFenxiaoOrder.indexOf('17452') == -1 && this.isFenxiaoOrder.indexOf('17453') == -1) {
            this.isFenxiaoSign = '1'
        } else if (this.isFenxiaoOrder.indexOf('17451') !== -1 && this.isFenxiaoOrder.indexOf('17452') !== -1 || this.isFenxiaoOrder.indexOf('17453') !== -1) {
            this.isFenxiaoSign = '3'
            this.isFenxiaoSignActive = '2'
        }
        if (this.channelOrderList.length == 0) {
            //筛选回调已经调用
            // this.getChatotalnnelList()
        }
    },
    methods: {
        // 去支付
        handleToPay(item){
            if(item.feeList) {
                let feeCode = item.feeList[0]
                this.$router.push({
                    path: "/serviceFeeModule/payDetail",
                    query: {
                        id:feeCode,
                        batch:0,
                        b2bOrderCode: item.documentNum,
                        orgName: '订单号',
                        channel: 1
                    }
                });
            } else {
                this.$message.warning('暂无待支付订单')
            }
        },
        gotoRerurn(dom, id) {
            let routeUrl = this.$router.resolve({
                path: '/channelReturns/returnEntry',
                query: {
                    document: dom,
                    id: id
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        handleTab(type) {
            if (this.isFenxiaoSign == '3') {
                this.isFenxiaoSignActive = type
            }
            this.isDataViewMoreFun()
            if (type == 1) {
                //渠道采购
                // this.$store.commit("filtrate/SET_CONFIG_STR", {toPath: this.$route, marketModels: '17451'});
                this.$router.push({
                    path: "/order/dms",
                });
            } else {
                //海信采购
                // this.$store.commit("filtrate/SET_CONFIG_STR", {toPath: this.$route, marketModels: '17452'});
                this.$router.push({
                    path: "/order/cis",
                });
            }

            //采购方式切换标志
            this.isChange = true
        },
        //加入购物车
        addCart(productCode, orgCode, num) {
            let params = {
                productId: productCode,
                orgId: orgCode,
                num: num,
                policyId: '' //政策id
            };
            this.$store.dispatch("user/addToCart", params);
        },
        //判断数据是否有选中 赋值class
        isDataViewMoreFun() {
            // isView?'type-wrap-active':''
            let cls = '';
            let count = 0;
            for (let itemL1 of this.filList) {
                for (let itemL2 of itemL1.list) {
                    if (itemL2.isActive && itemL2.name != '全部') {
                        count++
                    }
                }
            }
            // debugger
            if (count > 0) {
                cls = 'fil-checked'
            }
            return cls
        },
        // 打开取消订单弹窗
        openCancleChannelOrderAll(id,saleItem){
            this.cancelId = id
            this.cancelItem = saleItem
            this.visibleInfo = true
        },
        // 取消订单
        cancleChannelOrderAll() {
            let param = {
                userAccount: this.account,
                purchaseOrderId:  this.cancelId
            }
            this.$emit('postSpinTrue', true)
            cancleChannelOrder(param).then(res => {
                this.$emit('postSpinTrue', false)
                let data = JSON.parse(res.data)
                if (data.code == 0) {
                    this.$message.success('订单取消成功')
                    if(this.cancelItem.userActivityCode) {
                        let userActivityCode = this.cancelItem .userActivityCode
                        let productIds = []
                        let dmsOrderCode = this.cancelItem .documentNum
                        let qtys = []

                        this.cancelItem .purchaseOrderItem.forEach(element => {
                            productIds.push(element.productCode) 
                            qtys.push(-(element.orderedQty))
                        });
                        let changeDta = {
                            userActivityCode:userActivityCode,
                            productIds:productIds.join(','),
                            dmsOrderCode:dmsOrderCode,
                            qtys:qtys.join(',')
                        }
                        // 数据回滚接口
                        changeTransFlag(changeDta).then(res => {
                        })
                          
                    }
                    this.getChannelList()
                } else {
                    this.$message.success(data.msg)
                }
                this.getChannelList();
            }).catch(err => {
                console.log('err', err);
            })
        },
        // 列表
        getChannelList() {
            this.pageLoadFlag = true
            this.showListChannel = false
            // this.$emit('postSpinTrue',true)
            getChannelOrderList(this.channelFilter).then(res => {
                this.pageLoadFlag = false
                let data = JSON.parse(res.data)
                // this.$emit('postSpinTrue',false)
                if (data.code == 0) {
                    this.channelOrderList = data.data

                    this.showListChannel = true
                    this.totalPagesChannel = data.page.totalPage
                    this.totalCount = data.page.totalCount
                    this.currentPageChannel = data.page.pageNo
                    this.channelOrderList.forEach(item => {

                        if(item.feeOverTime) {
                            let orderServideFeeData = item.feeOverTime
                            item.leftTime = 0
                            let  date = new Date();
                            let  now = date.getTime();
                            //设置截止时间
                            // var endDate = new Date("2020-1-22 23:23:23");
                            var end = new Date(orderServideFeeData);
                            //时间差
                            item.leftTime = end - now;
                            //定义变量 d,h,m,s保存倒计时的时间
                            if (item.leftTime >= 0) {
                                item.d = Math.floor(item.leftTime / 1000 / 60 / 60 / 24);
                                item.h = Math.floor(item.leftTime / 1000 / 60 / 60 % 24);
                                item.m = Math.floor(item.leftTime / 1000 / 60 % 60) < 10?'0'+ Math.floor(item.leftTime / 1000 / 60 % 60):Math.floor(item.leftTime / 1000 / 60 % 60);
                                item.s = Math.floor(item.leftTime / 1000 % 60)< 10?'0'+ Math.floor(item.leftTime / 1000 % 60):Math.floor(item.leftTime / 1000 % 60);
                                item.sum_h = this.d * 24 + this.h
                            }
                        }
                        item.purchaseOrderItem.forEach(goodsItem => {
                            if ((goodsItem.price.toString()).indexOf(".") == -1) {
                                goodsItem.price = Number(goodsItem.price).toFixed(2)
                            }
                        })
                    })
                    this.channelOrderList.forEach((item) => {
                        item.dataArr = []
                        if(item.discountTypeName == '组合购') {
                          item.dataArr=[]
                          item.dataArr.push(this.changeData(item.purchaseOrderItem))
                         
                          this.$forceUpdate()
                        }
                        item.purchaseOrderItem.forEach((goodsItem) => {
                            if ((goodsItem.price.toString()).indexOf(".") == -1) {
                                goodsItem.price = Number(goodsItem.price).toFixed(2)
                            }
                        });
                      });
                } else {
                    this.pageLoadFlag = false
                    this.channelOrderList = []
                    this.showListChannel = true
                    this.totalPagesChannel = 0
                    this.totalCount = 0
                }

            }).catch(err => {
                this.pageLoadFlag = false
                console.log('err', err);
            })
        },
        //组合购数据更改
        changeData(items) {
            var map = {},
                dest = [];
            for (var i = 0; i < items.length; i++) {
                var ai = items[i];
                if (!map[ai.productGroup]) {
                    dest.push({
                        productGroup: ai.productGroup,
                        name: ai.productGroupRemark,
                        num: ai.packageRateNum,
                        data: [ai]
                    });
                    map[ai.productGroup] = ai;
                } else {
                    for (var j = 0; j < dest.length; j++) {
                        var dj = dest[j];
                        if (dj.productGroup == ai.productGroup) {
                            dj.data.push(ai);
                            break;
                        }
                    }
                }
            }

            return dest;
        },
        // 加载更多
        clickMoreChannel() {
            // this.filterFrom.page += 1
            this.channelFilter.page.pageNo = this.channelFilter.page.pageNo + 1
            this.pageLoadFlag = true
            getChannelOrderList(this.channelFilter).then(res => {
                this.pageLoadFlag = false
                let data = JSON.parse(res.data)
                this.channelOrderList = this.channelOrderList.concat(data.data)
                this.channelOrderList.forEach(item => {
                    item.purchaseOrderItem.forEach(goodsItem => {
                        if ((goodsItem.price.toString()).indexOf(".") == -1) {
                            goodsItem.price = Number(goodsItem.price).toFixed(2)
                        }
                    })
                })
                this.channelOrderList.forEach((item) => {
                    item.dataArr = []
                    if(item.discountTypeName == '组合购') {
                      item.dataArr=[]
                      item.dataArr.push(this.changeData(item.purchaseOrderItem))
                     
                      this.$forceUpdate()
                    }
                    item.purchaseOrderItem.forEach((goodsItem) => {
                        if ((goodsItem.price.toString()).indexOf(".") == -1) {
                            goodsItem.price = Number(goodsItem.price).toFixed(2)
                        }
                    });
                  });
                // this.showListChannel = true
                this.totalPagesChannel = data.page.totalPage
                this.totalCount = data.page.totalCount
                this.currentPageChannel = data.page.pageNo
            }).catch(error => {
                this.pageLoadFlag = false
                console.log('下拉', error);
            })
        },
        // 时间排序
        changeFangChannel() {
            if (this.creatDateChannel == 0) {
                this.creatDateChannel = 1;
                this.channelFilter.page.sortOrder = 'desc'
            } else if (this.creatDateChannel == 1) {
                this.creatDateChannel = 2;
                this.channelFilter.page.sortOrder = 'asc'
            } else {
                this.creatDateChannel = 0;
                this.channelFilter.page.sortOrder = ''
            }
            this.getChannelList()
        },
        // 搜索
        onSearchOrderCode() {
            // for (var a in this.channelFilter.terms) {
            //     this.channelFilter.terms[a] = ''
            // }
            this.channelFilter.page.pageNo = 1
            this.channelFilter.terms.documentNum = this.searchChannelOrderNo
            this.getChannelList()
        },
        onFilterChangeChannel(checkedObj) {
            //合并入参请求对象
            this.channelFilter.terms = {
                ...this.channelFilter.terms,
                ...checkedObj[1]
            };
            this.channelFilter.page = {
                ...this.channelFilter.page,
                pageNo: 1
            };
            //查询列表
            this.getChannelList()
        },
        goTo(path,item,type) {
            if( type=='detail' && item.isProtoType) {
                let path = '/prototyZone/detailQD?id='+item.id
                let routeUrl = this.$router.resolve({
                    path: path,
                });
                window.open(routeUrl.href, "_blank");
                return
            }
            // this.$router.push(path);
            let routeUrl = this.$router.resolve({
                path: path,
            });
            window.open(routeUrl.href, "_blank");
        },
        toDetail(proCode, orgId) {
            // this.$router.push({
            //     path: '/product/detail',
            //     query: {
            //         productCode: proCode,
            //         orgId: orgId
            //     }
            // })
            let routeUrl = this.$router.resolve({
                path: '/product/detail',
                query: {
                    productCode: proCode,
                    orgId: orgId
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        // 筛选
        shaiClickChannel() {
            this.isViewChannel = !this.isViewChannel;
        },
        // 订单导出
        exportOrderChannel() {
            this.orderLoading = true
            const parms = JSON.parse(JSON.stringify(this.channelFilter))
            parms.page.pageNo = 1
            parms.page.pageSize = 300000
            exportOrderChannel(parms).then(res => {
                Util.blobToJson(res.data).then(content => {
                    if (content && content.msg == 'success') {
                        this.$message.success('下载成功，请到下载中心查看!')
                    }
                }).catch(err => {
                    Util.downloadFile(res.data, '渠道采购订单明细.xls')
                }).finally(() => {
                    this.orderLoading = false;
                })
            }).catch(error => {
                this.orderLoading = false
                console.log('订单导出', error);
            })
        },
        // 审核单导出
        exportOrderSapChannel() {
            this.shenLoading = true
            const parms = JSON.parse(JSON.stringify(this.channelFilter))
            parms.page.pageNo = 1
            parms.page.pageSize = 300000
            parms.terms.hasSoNum = 1
            exportOrderChannelShen(parms).then(res => {
                Util.blobToJson(res.data).then(content => {
                    if (content && content.msg == 'success') {
                        this.$message.success('下载成功，请到下载中心查看!')
                    }
                }).catch(err => {
                    Util.downloadFile(res.data, '渠道采购审核单.xls')
                }).finally(() => {
                    this.shenLoading = false;
                })
            }).catch(error => {
                this.shenLoading = false
                console.log('订单导出', error);
            })
        }
    }
}